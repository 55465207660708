const $ = require('jquery');
export default () => {
    let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if(windowWidth > 767) {
        $('.navBox__item-more').on('click',function(){
            $(this).next('.navBox__more').slideToggle(600);
            $(this).toggleClass('is-open');
            return false;
        })
        //slide内にis-currentがある場合slideをopen
        const current = document.getElementsByClassName('is-current')[0];
        current.parentNode.style.display = 'block';
    }
}
