import $ from 'jquery';
export default () => {
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if(windowWidth <= 767) {
        let scrollpos;
        //hamburger menu
        $('.tabItem--no1,.l-header__nav-megaMenu--no1').addClass('on');
        $('.spMenu').on('click',() => {
            if(!$('.spMenu').hasClass('on')){
                scrollpos = $(window).scrollTop();
                $('body').addClass('is-fixed').css({'top': -scrollpos});
            }else{
                $('body').removeClass('is-fixed').css({'top': 0});
                $('html,body').animate({scrollTop:scrollpos},0);
            }
            $('.spMenu').toggleClass('on');
            $('.l-header__nav').slideToggle(300);
        })
        //tab
        $('.tabItem').on('click',function(){
            $('.tabItem').removeClass('on');
            $(this).addClass('on');
            $('.l-header__nav-megaMenu').removeClass('on');
            const num = $(this).data('num');
            const showTarget = '.l-header__nav-megaMenu--'+num;
            $(showTarget).addClass('on');
        })
        //megamenu more
        $('.megaMenu__item-link.more').on('click',function(){
            $(this).toggleClass('on');
            $(this).next('.megaMenu__item-more').slideToggle(500);
            $(this).next('.megaMenu__item-more').toggleClass('on');
            return false;
        });
    }else{
        let ua = navigator.userAgent;
        if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
            $('.tabItem--no1').on('click',function(){
                $('.tabItem--no2').removeClass('on')
                $('.l-header__nav-megaMenu--no2').css({display:'none'})
                $(this).toggleClass('on');
                $('.l-header__nav-megaMenu--no1').css({display:'block'})
            })
            $('.tabItem--no2').on('click',function(){
                $('.tabItem--no1').removeClass('on')
                $('.l-header__nav-megaMenu--no1').css({display:'none'})
                $(this).toggleClass('on');
                $('.l-header__nav-megaMenu--no2').css({display:'block'})
            })

            $('.l-header__nav-megaMenu,.l-header__top,main,.l-footer').on('click',function(){
                $('.tabItem').removeClass('on')
                $('.l-header__nav-megaMenu').css({display:'none'})
            });

            $(window).on('scroll', function(){
                $('.tabItem').removeClass('on');
                $('.l-header__nav-megaMenu').css({display:'none'})
            })
        }else{
            $('.tabItem').hover(function(){
                $('.l-header__nav-megaMenu').removeClass('on');
                const num = $(this).data('num');
                const hoverTarget = '.tabItem--'+num;
                const showTarget = '.l-header__nav-megaMenu--'+num;
                $(hoverTarget).addClass('on');
                $(showTarget).addClass('on');
            },function(){
                $('.tabItem,.l-header__nav-megaMenu').removeClass('on');
            })

            $('.l-header__nav-megaMenu--no1').hover(function(){
                $('.tabItem--no1').addClass('on');
            },function(){
                $('.tabItem--no1').removeClass('on');
            })
            $('.l-header__nav-megaMenu--no2').hover(function(){
                $('.tabItem--no2').addClass('on');
            },function(){
                $('.tabItem--no2').removeClass('on');
            })
        }
    }
};
