/*import*/
import $ from 'jquery';
import ChangeImg from './modules/changeImg';
import Smoothscroll from './modules/smoothscroll';
import Menu from './modules/Menu';
import Reload from './modules/reload';
import Header from './modules/header';
import SideNav from './modules/sideNav';
import Form from './modules/form';
/*create instance*/
/*load event*/
document.addEventListener('DOMContentLoaded', () => {
    ChangeImg();
    Smoothscroll();
    Menu();
    Reload();
    Header();
    if(document.querySelector('.c-content__sideNav')){
        SideNav();
    }
    const target = $('[class^="p-"]').attr('id');
    switch (target) {
        case 'contact':
        console.log('contact');
            Form();
            break;
        default:
            break;
    }
})
