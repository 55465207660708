export default () => {
    const checkbox = document.getElementById('agree');
    checkbox.addEventListener('click',()=>{
        if(checkbox.checked) {
            document.getElementById('submitBtn').disabled = "";
            document.getElementById('submitBtn').classList.add('on')
        }else {
            document.getElementById('submitBtn').disabled = "disabled";
            document.getElementById('submitBtn').classList.remove('on')
        }
    })
    if(checkbox.checked) {
        document.getElementById('submitBtn').disabled = "";
        document.getElementById('submitBtn').classList.add('on')
    }else {
        document.getElementById('submitBtn').disabled = "disabled";
        document.getElementById('submitBtn').classList.remove('on')
    }
};
