const $ = require('jquery');
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
const controller = new ScrollMagic.Controller;
export default () => {
    let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let ua = navigator.userAgent;
    if(windowWidth > 767) {
        //header scroll animation
        if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
            new ScrollMagic.Scene({
                triggerElement: "body",
                offset: 900
            })
            .setClassToggle(".l-header", "set1")
            //.addIndicators()
            .addTo(controller);

            new ScrollMagic.Scene({
                triggerElement: "body",
                offset: 1000
            })
            .setClassToggle(".l-header", "set2")
            //.addIndicators()
            .addTo(controller);

            new ScrollMagic.Scene({
                triggerElement: "body",
                offset: 1050
            })
            .setClassToggle(".l-header", "is-fixed")
            //.addIndicators()
            .addTo(controller);
        }else{
            new ScrollMagic.Scene({
                triggerElement: "body",
                offset: 600
            })
            .setClassToggle(".l-header", "set1")
            //.addIndicators()
            .addTo(controller);

            new ScrollMagic.Scene({
                triggerElement: "body",
                offset: 650
            })
            .setClassToggle(".l-header", "set2")
            //.addIndicators()
            .addTo(controller);

            new ScrollMagic.Scene({
                triggerElement: "body",
                offset: 780
            })
            .setClassToggle(".l-header", "is-fixed")
            //.addIndicators()
            .addTo(controller);
        }
    }

    //backTop scroll animation
    new ScrollMagic.Scene({
        triggerElement: "body",
        offset: 600
    })
    .setClassToggle(".backTop", "is-show")
    //.addIndicators()
    .addTo(controller);

    new ScrollMagic.Scene({
       triggerElement: '.l-footer',
       offset: 0,
       triggerHook : "onEnter"
    })
    .setClassToggle(".backTop", "is-stop")
    //.addIndicators()
    .addTo(controller);
}
