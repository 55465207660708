export default () => {
    //sp⇔pc reload
    let windowSize;
    let timer;
    const first_ww = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if(first_ww > 767) {
        windowSize = 'pc';
    }else{
        windowSize = 'sp';
    }
    window.addEventListener('resize',function(){
        const after_ww = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const judge = () =>{
            if(windowSize=='pc'&&after_ww <= 767||windowSize=='sp'&&after_ww > 767) {
                 window.location.reload();
            }
        }
        clearTimeout(timer);
        timer = setTimeout(function() {
            judge();
            //console.log(windowSize);
        }, 200);
    })
};
